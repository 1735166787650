import { defineComponent, computed, ref, watch } from '@vue/composition-api';
import PriceInput from '@/uikit/PriceInput.vue';
import FormIncrementedInput from '@/shared/components/form/FormIncrementedInput.vue';
import FormCheckbox from '@/shared/components/form/FormCheckbox.vue';
import Toggle, { Option } from '@/uikit/Toggle.vue';
import { SPACE_CONFIGURATIONS } from '@/shared/const';
export default defineComponent({
    components: {
        PriceInput,
        FormCheckbox,
        FormIncrementedInput,
        Toggle,
        Option
    },
    props: {
        label: {
            type: [String, Object],
            default: ''
        },
        required: {
            type: Boolean,
            default: false
        },
        options: {
            type: Array,
            default: () => []
        },
        value: {
            type: Array,
            default: () => []
        },
        error: {
            type: String,
            default: ''
        }
    },
    setup(props, { emit }) {
        const configurations = ref([]);
        const checkedConfigurations = computed(() => {
            return configurations.value.filter(configuration => configuration.checked);
        });
        const configurationsDisabled = computed(() => {
            return checkedConfigurations.value.length < 2;
        });
        const onMakeDefault = (selectedConfiguration) => {
            configurations.value.map(configuration => {
                configuration.custom = selectedConfiguration !== configuration;
                if (!configuration.custom) {
                    configuration.price = 0;
                }
            });
        };
        const onMakeCustom = (selectedConfiguration) => {
            if (!selectedConfiguration.custom) {
                selectedConfiguration.custom = true;
                if (checkedConfigurations.value.length > 0) {
                    const index = checkedConfigurations.value[0] === selectedConfiguration ? 1 : 0;
                    checkedConfigurations.value[index].custom = false;
                    checkedConfigurations.value[index].price = null;
                }
            }
        };
        const onCheck = (selectedConfiguration) => {
            if (selectedConfiguration.checked) {
                if (configurationsDisabled.value) {
                    onMakeDefault(selectedConfiguration);
                }
            }
            else {
                onMakeCustom(selectedConfiguration);
            }
        };
        watch(() => props.value, () => {
            configurations.value = props.value.map(c => {
                const configuration = props.value.find(el => el.configuration_id === c.configuration_id);
                if (configuration) {
                    return configuration;
                }
            });
        }, { immediate: true });
        watch(() => props.options, () => {
            if (props.options) {
                configurations.value = props.options.map(c => {
                    const configuration = props.value.find(el => el.configuration_id === c.configuration_id);
                    if (configuration) {
                        return configuration;
                    }
                    return {
                        checked: false,
                        custom: true,
                        attendees: null,
                        price: null,
                        icon: c.icon || '',
                        configuration_id: c.configuration_id,
                        configuration_type: c.configuration_type
                    };
                });
                emit('input', configurations.value);
            }
        }, { immediate: true });
        return {
            configurations,
            configurationsDisabled,
            SPACE_CONFIGURATIONS,
            onCheck,
            onMakeCustom,
            onMakeDefault
        };
    }
});
