import { defineComponent, ref, computed, watch, getCurrentInstance, onMounted } from '@vue/composition-api';
import { ROUTE_LIST_NAMES } from '@/config/router/appRoutes';
import PageLoadingBar from '@/shared/components/PageLoadingBar.vue';
import SpaceConfigurationsSelector from '@/pages/vendor/space/configurations/SpaceConfigurationsSelector.vue';
import SpaceConfigurationsForm from '@/pages/vendor/space/configurations/forms/SpaceConfigurationsForm';
import toJSON from '@/api/base/toJSON';
import getConfigurations from '@/api/space/getConfigurations';
import getSpace from '@/api/space/getById';
import { cloneDeep, isEqual } from 'lodash';
import InputWithPostfix from '@/components/InputWithPostfix';
import useAsyncPage from '@/shared/composables/useAsyncPage';
export default defineComponent({
    components: {
        PageLoadingBar,
        SpaceConfigurationsSelector,
        InputWithPostfix
    },
    props: {
        revert: {
            type: Boolean,
            default: false
        },
        onSave: {
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {
        const { root, emit } = context;
        const instance = getCurrentInstance();
        const errors = ref([]);
        const form = ref(new SpaceConfigurationsForm());
        const formLoaded = ref({});
        const saving = ref(false);
        const spaceConfigurations = computed(() => globalThis.$store.getters['$_vendor/spaceConfigurations']);
        const spaceStaticConfigurations = computed(() => globalThis.$store.getters['$_vendor/spaceStaticConfigurations']);
        const checkErrors = (scroll) => {
            errors.value = [];
            form.value.validate(instance);
            // TODO: Move this to form validation
            if (form.value.size) {
                if (!Number.isInteger(Number(form.value.size))) {
                    form.value.size = Math.round(parseFloat(form.value.size));
                }
            }
            console.log(form.value.size);
            Object.keys(form.value).forEach(key => {
                const err = form.value.errors(key);
                if (err) {
                    errors.value.push(key);
                }
            });
            if (errors.value.length && scroll) {
                root.$scrollTo(`#space_${errors.value[0]}`, 1500, { offset: -100 });
            }
            return errors.value;
        };
        const canContinue = computed(() => {
            if (saving.value) {
                checkErrors(false);
                return !errors.value.length;
            }
            return true;
        });
        watch(() => spaceConfigurations.value, value => {
            if (value) {
                form.value.loadFromModel(value);
            }
        }, { immediate: true });
        watch(() => props.revert, () => {
            if (props.revert) {
                form.value = cloneDeep(formLoaded.value);
            }
        }, { immediate: true });
        watch(() => props.onSave, () => {
            if (props.onSave) {
                formLoaded.value = cloneDeep(form.value);
            }
        }, { immediate: true });
        watch(() => [form.value.configurations, form.value.size], () => {
            if (!formLoaded.value) {
                return;
            }
            if (formLoaded.value.configurations &&
                formLoaded.value.configurations.length &&
                !isEqual(form.value.configurations, formLoaded.value.configurations)) {
                emit('settingsChanged', {
                    page: 'configurations',
                    data: form.value.configurations,
                    prevData: formLoaded.value.configurations
                });
            }
            if (formLoaded.value.size &&
                formLoaded.value.size !== form.value.size) {
                emit('updateSpaceDetails', {
                    data: { spaceSize: form.value.size }
                });
            }
            if (formLoaded.value.size === form.value.size &&
                isEqual(form.value.configurations, formLoaded.value.configurations)) {
                emit('noChanges');
            }
        }, { immediate: true, deep: true });
        const mainAction = async () => {
            saving.value = true;
            checkErrors(true);
            if (!errors.value.length) {
                globalThis.$store.commit('$_vendor/SET_SPACE_CONFIGURATIONS', form.value);
                globalThis.$router.push({
                    name: ROUTE_LIST_NAMES.ONBOARDING.SPACE.BOOKING_RULES,
                    params: { venueId: root.$route.params.venueId }
                });
            }
        };
        watch(() => form.value, () => {
            if (!spaceConfigurations.value ||
                form.value.configurations.every(config => !config.checked))
                return;
            globalThis.$store.commit('$_vendor/SET_SPACE_CONFIGURATIONS', form.value);
        }, { deep: true, immediate: true });
        const { dataLoaded, callAPIAction } = useAsyncPage();
        onMounted(async () => {
            const { spaceId, venueId } = root.$route.params;
            await callAPIAction('$_vendor/loadSpaceFromStorage');
            await callAPIAction('$_vendor/loadSpaceStaticConfigurations');
            form.value.venueId = venueId;
            if (spaceId) {
                const space = await toJSON(getSpace(spaceId));
                const configurations = await toJSON(getConfigurations(spaceId));
                form.value.size = space.space_size;
                form.value.configurations = form.value.configurations.map(el => {
                    const configuration = configurations.find(c => c.configuration_id === el.configuration_id);
                    if (configuration) {
                        return {
                            ...el,
                            attendees: configuration.max_guests,
                            checked: true,
                            custom: !configuration.is_default,
                            price: configuration.configuration_price
                        };
                    }
                    return el;
                });
            }
            formLoaded.value = cloneDeep(form.value);
        });
        return {
            canContinue,
            dataLoaded,
            form,
            spaceStaticConfigurations,
            checkErrors,
            mainAction
        };
    }
});
